import "bootstrap/js/dist/util";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/carousel";

import './sass/product_site.scss';

import initializeHeader from './js/header';

if (module.hot) {
    module.hot.accept('./js/header.js', function () {
        initializeHeader();
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initializeHeader();
    showHideDetail ();
    addDetails ();
    productSiteForm();
    multiCarousel();

    function elementDisplay(elementId, displayStyle) {
        document.getElementById(elementId).style.display = displayStyle;
    }

    /** Show and hide on form contact page*/
    function showHideDetail (){
        ['show_contact_page', 'thank_you_value', 'show_site_landing', 'email_us_today', 'bi_check_2']
            .forEach((elementId) => elementDisplay(elementId, "none"));
        const showHide = document.querySelector('.can_product_help #product_home_page_help #buttonShowHide');
        showHide.addEventListener("click", () => {
            elementDisplay('show_contact_page', "initial");
            elementDisplay('email_us_today', "flex");
            elementDisplay('buttonShowHide', "none");
        });
    }

    /** Show contact us */
    function addDetails () {
        const showDetail = document.querySelector('.can_product_help #email_us_today a');
        showDetail.addEventListener("click", (e, type='otliam', name='stcudorp', loc='aplatac') => {
            e.preventDefault();
            const product_home_page_title = JSON.parse(document.getElementById("productHomePageTitle").textContent);
            function invert(value) {
                return value.split('').reverse().join('');
            }
            let newLink = document.createElement('a');
            newLink.href = `${invert(type)}:${invert(name)}@${invert(loc)}.io?subject=${ product_home_page_title }`;
            newLink.target = '_blank'
            newLink.click();
            newLink.remove();
        })
    }

    /** show MultiCarouselImage */
    function multiCarousel(){
        const container = document.getElementById('MultiCarouselImage');
        if (!container) {
            return;
        }
        const carousel = new MultiCarousel({
            target: container,
            props: {
                delay: 2000,
                items: [...container.children],
                count: 3,
                controls: [ 
                    'previous',
                    'next',
                ]
            }
        });
    }

    /** product form for contact us*/ 
    function productSiteForm() {
        const product_form = document.querySelector('.can_product_help #show_contact_page');
        product_form.firstElementChild.addEventListener("submit", (e) => {
            e.preventDefault();
            const path = JSON.parse(document.getElementById("urlPath").textContent);
            const formData = new FormData(e.target);
            fetch(path, {
                method: 'POST',
                mode: "cors",
                body: formData,
            })
            .then((response) =>{  
                hideForm();
            })
            .catch(error => {
                console.error('Error:', error);
            })
        })

        /** Show and hide on form contact page*/
        function hideForm(){
            Object.entries({
                "contact-us-product": "none",
                "product_home_page": "none",
                "product_home_page_help": "none",
                "show_site_landing": "initial",
                "thank_you_value": "initial",
                "bi_check_2": "inline-block",
            }).forEach(([elementId, displayStyle]) => elementDisplay(elementId, displayStyle));
        }
    }

    /* email required */
    const emailInput = document.getElementById('id_email');

    emailInput.addEventListener('input', validateEmailInput);
    emailInput.addEventListener('invalid', validateEmailInput)

    function validateEmailInput(){
        const emailErrorMessage = document.getElementById('id_email_invalid');
        const envelopeIcon = document.getElementById('envelope-la');
        if (emailInput.value == '') {
            emailErrorMessage.innerHTML = "<i class='las la-exclamation-circle'></i>Your email address is required to continue";  
            emailInput.className = "invalid_email";  
            envelopeIcon.style.color = "#F3215B";
        }
        else if(emailInput.validity.typeMismatch){
            emailErrorMessage.innerHTML = "<i class='las la-exclamation-circle'></i>Please enter a valid email address";  
            emailErrorMessage.className = "invalid_email_content";  
            emailInput.className = "invalid_email";  
            envelopeIcon.style.color = "#F3215B";  
        }
        else {
            emailErrorMessage.innerHTML = "";  
            emailErrorMessage.className = "";  
            emailInput.className = "";  
            envelopeIcon.style.color = '#0070f0';  
        }
    }

    /* phone number validity */
    const phoneInput = document.getElementById('id_phone');

    phoneInput.addEventListener('input', validatePhoneInput);
    phoneInput.addEventListener('invalid', validatePhoneInput)

    function validatePhoneInput() {
        const phoneErrorMessage = document.getElementById('id_phone_invalid');
        const phoneIcon = document.getElementById('phone-icon');
        if (phoneInput.validity.patternMismatch){
            phoneErrorMessage.innerHTML = "<i class='las la-exclamation-circle'></i>Minimum length is 8. Please enter a valid phone number starting with '+' and your country code. e.g. +670.";  
            phoneErrorMessage.className = "invalid_email_content";  
            phoneInput.className = "invalid_email";  
            phoneIcon.style.color = "#F3215B";  
        }
        else {
            phoneErrorMessage.innerHTML = "";  
            phoneErrorMessage.className = "";  
            phoneInput.className = "";  
            phoneIcon.style.color = '#0070f0';  
        }
    }
});
